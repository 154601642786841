import React, { Component } from "react";
import PropTypes from "prop-types";

import style from "./invest.module.sass";
import Title from "../Title";
import classNames from "classnames";

class WhyInvestWithAsGold extends Component {
    state = {
        isOtherBenefitsOpen: false,
    };

    render = () => {
        const { isOtherBenefitsOpen } = this.state;
        const { mb } = this.props;

        return (
            <section
                className={style.whyInvestWithAs}
                style={{ marginBottom: `${mb}px` }}
            >
                <div className={style.whyInvestWithAsInner}>
                    <div className={style.whyInvestWithAsIndent}>
                        <Title color={"gold"} type={"h1"} mb={20}>
                            Investice do&nbsp;zlata - s&nbsp;námi vždy bezpečně
                            a&nbsp;výhodně
                        </Title>
                        <p className={style.whyInvestWithAsText}>
                            KAPITOL vám spolu s&nbsp;obchodním partnerem,
                            společností IBIS InGold, přináší vždy něco navíc.
                            Investiční zlato je při nákupu osvobozeno
                            od&nbsp;DPH a&nbsp;ze&nbsp;zisku při prodeji zlata
                            neplatíte daň z&nbsp;příjmu.
                        </p>
                        <Title
                            type={"h4"}
                            color={"goldNegative"}
                            mb={30}
                            mt={50}
                        >
                            Jaké jsou další přínosy?
                        </Title>

                        <div className={style.whyInvestWithAsBenefits}>
                            <div className={style.whyInvestWithAsBenefitsRow}>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/kodex.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Etický kodex
                                        </Title>
                                        <p>
                                            Vždy se řídíme nejpřísnějšími
                                            etickými pravidly a&nbsp;principy.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/veriscan.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Veriscan
                                        </Title>
                                        <p>
                                            Tato pokročilá technologie nám
                                            umožňuje bezpečně ověřit pravost
                                            kovu.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={style.whyInvestWithAsBenefitsRow}>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/likvidita.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Zajištěná likvidita
                                        </Title>
                                        <p>
                                            Při odkupu zlata budete mít peníze
                                            na&nbsp;účtu do&nbsp;tří dnů.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/garance_prvodrzitelstvi.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Garantujeme prvodržitelství
                                        </Title>
                                        <p>
                                            Naši klienti jsou vždy prvními
                                            majiteli slitku či&nbsp;mince.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {isOtherBenefitsOpen && (
                                <React.Fragment>
                                    <div
                                        className={
                                            style.whyInvestWithAsBenefitsRow
                                        }
                                    >
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/trezor.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    Úschova zlata pro klienty
                                                    zdarma
                                                </Title>
                                                <p>
                                                    A&nbsp;to
                                                    v&nbsp;nejbezpečnějším
                                                    trezoru v&nbsp;ČR.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/klientsky_portal.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    Klientský portál
                                                </Title>
                                                <p>
                                                    V&nbsp;něm můžete své
                                                    investice do&nbsp;zlata
                                                    sledovat i&nbsp;spravovat.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            style.whyInvestWithAsBenefitsRow
                                        }
                                    >
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/pamp_logo.svg")}
                                                alt=""
                                                className={style.iconPamp}
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    Zlato z&nbsp;prestižní
                                                    rafinérie PAMP
                                                </Title>
                                                <p>
                                                    Sázíme na&nbsp;zlaté slitky
                                                    s&nbsp;nejvyšší ryzostí
                                                    999,9/1000 tradiční
                                                    švýcarské značky PAMP.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/basket-gold.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    Vlastní e shop a&nbsp;mince
                                                </Title>
                                                <p>
                                                    V&nbsp;našem e-shopu
                                                    nabízíme i&nbsp;unikátní
                                                    sběratelské mince KAPITOL.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            style.whyInvestWithAsBenefitsRow
                                        }
                                    >
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/tax.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    Žádné daně
                                                </Title>
                                                <p>
                                                    Při&nbsp;nákupu neplatíte
                                                    DPH, při&nbsp;prodeji ani
                                                    daň z&nbsp;příjmů.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/ceska_mincovna.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    Mince z&nbsp;České mincovny
                                                </Title>
                                                <p>
                                                    Spolupracujeme
                                                    s&nbsp;prestižním českým
                                                    výrobcem oběžných
                                                    a&nbsp;investičních mincí.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>

                        {!isOtherBenefitsOpen && (
                            <div className={style.whyInvestWithAsOther}>
                                <button
                                    onClick={() =>
                                        this.setState({
                                            isOtherBenefitsOpen: true,
                                        })
                                    }
                                    className={classNames({
                                        [style.gold]: true,
                                    })}
                                >
                                    <span className={"icon-load_more"} />
                                    Další výhody
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    };
}

WhyInvestWithAsGold.propTypes = {
    mb: PropTypes.number,
};

WhyInvestWithAsGold.defaultProps = {
    mb: 0,
};

export default WhyInvestWithAsGold;
