import React from "react";
import classNames from "classnames";
import style from "./investBanner.module.sass";
import Title from "../Title";
import buttonStyle from "../Button/button.module.sass";
import { Link } from "gatsby";

import { TYPE_GOLD, TYPE_PLATINUM, TYPE_SILVER } from "../../constants";

const InvestBanner = ({ type }) => (
    <div
        className={classNames({
            [style.career]: true,
            [style[type]]: true,
        })}
    >
        <div className={style.careerInner}>
            <div className={style.careerIndent}>
                <div>
                    <Title color={"white"} type={"h2"} mb={10}>
                        CHCETE INVESTOVAT?
                    </Title>
                    <p>
                        Ozvěte se nám, pobavíme se spolu o&nbsp;tom,
                        jak&nbsp;investovat do {type === TYPE_GOLD && "zlata"}
                        {type === TYPE_SILVER && "stříbra"}
                        {type === TYPE_PLATINUM && "platiny"}.
                    </p>
                </div>
                <div>
                    <Link
                        to={`/${type}-form`}
                        className={buttonStyle.buttonBlueDark}
                    >
                        Kontaktujte nás
                    </Link>
                </div>
            </div>
        </div>
    </div>
);

export default InvestBanner;
